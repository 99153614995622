import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Popconfirm, Select, Spin, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import { Address } from '@ant-design/web3';
import { get, isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */
import SearchUser from '@/components/search-user';
import Permission from '@/components/permission';

/**
 * APIs
 */
import { delistMemeCoin, gMemeCoin } from '@/services/memecoin';

/**
 * Constants
 */
const memeCoinStatusMap = {
    Unrecommended: <FormattedMessage id="mklf.meme.c.Unrecommended" />,
    Recommended: <FormattedMessage id="mklf.meme.c.Recommended" />,
    Launching: <FormattedMessage id="mklf.meme.c.Launching" />,
    Launched: <FormattedMessage id="mklf.meme.c.Launched" />,
    Delisted: <FormattedMessage id="mklf.meme.c.Delisted" />,
};

const memeCoinStatuOptions = Object.entries(memeCoinStatusMap).map(([value, label]) => {
    return {
        label,
        value,
    };
});

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';

import type { MemeCoinResult, FeGMemeCoinParams } from '@/services/memecoin';

// type SelectOptions = {
//     label: string | number;
//     value: string | number;
// };

/**
 * State
 */
type State = {
    dataSource: MemeCoinResult[];
};

const PageNFTMarketplaceMemecoin: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();
    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });
    const [tableCellViolateLoading, setTableCellViolateLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Request
     */

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<FeGMemeCoinParams>({});

    const {
        loading: loadingMemecoin,
        refresh: refreshMemecoin,
        run: runMemecoin,
    } = useRequest(gMemeCoin, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: get(data, 'list', []),
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<FeGMemeCoinParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            console.log(values);
            if (values._created_date) {
                values.created_start = values._created_date[0].startOf('d').format();
                values.created_end = values._created_date[1].endOf('d').format();
                values._created_date = undefined;
            }

            if (values.name) {
                values.name = trim(values.name);
            }

            if (values.symbol) {
                values.symbol = trim(values.symbol);
            }

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<MemeCoinResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'name',
                ellipsis: true,
                title: 'Name',
                width: 200,
            },
            {
                dataIndex: 'symbol',
                ellipsis: true,
                title: 'Ticker',
                width: 200,
            },
            {
                dataIndex: 'description',
                ellipsis: true,
                title: <FormattedMessage id="tc.f.desc" />,
            },
            {
                dataIndex: 'image',
                valueType: 'image',
                title: <FormattedMessage id="cp.f.image" />,
                width: 80,
            },
            {
                dataIndex: 'socrates_group',
                ellipsis: true,
                title: <FormattedMessage id="mklf.meme.t.c.group" />,
                renderText: (text) =>
                    !text ? (
                        '-'
                    ) : (
                        <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                            {text}
                        </a>
                    ),
                width: 200,
            },
            {
                dataIndex: 'x',
                ellipsis: true,
                title: 'X',
                renderText: (text) =>
                    !text ? (
                        '-'
                    ) : (
                        <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                            {text}
                        </a>
                    ),
                width: 200,
            },
            {
                dataIndex: 'website',
                ellipsis: true,
                title: <FormattedMessage id="mklf.meme.t.c.website" />,
                renderText: (text) =>
                    !text ? (
                        '-'
                    ) : (
                        <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                            {text}
                        </a>
                    ),
                width: 200,
            },
            {
                dataIndex: 'telegram',
                ellipsis: true,
                title: 'Telegram',
                renderText: (text) =>
                    !text ? (
                        '-'
                    ) : (
                        <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                            {text}
                        </a>
                    ),
                width: 200,
            },
            {
                dataIndex: 'current_price',
                ellipsis: true,
                title: <FormattedMessage id="mklf.meme.t.c.price" />,
                width: 200,
            },
            {
                dataIndex: 'market_cap',
                ellipsis: true,
                title: <FormattedMessage id="mklf.meme.t.c.marketCap" />,
                render: (_, enrity) => {
                    if (!enrity.market_cap) return '-';
                    return `${enrity.market_cap}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
                width: 200,
            },
            {
                dataIndex: 'contract',
                title: <FormattedMessage id="mknft.t.c.eAddress" />,
                render: (_, entity) =>
                    !get(entity, 'contract') ? (
                        '-'
                    ) : (
                        <Address
                            ellipsis={{
                                headClip: 8,
                                tailClip: 6,
                            }}
                            copyable
                            address={get(entity, 'contract')}
                        />
                    ),
                width: 200,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                valueType: 'select',
                valueEnum: memeCoinStatusMap,
                width: 200,
            },
            {
                dataIndex: '_creator',
                title: <FormattedMessage id="t.c.createdBy" />,
                render: (_, entity) => {
                    const { username = '-', nickname = '-', user_id } = entity.creator || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: user_id, tooltips: 'Copy UID' }}>
                                @{username}
                            </Typography.Text>
                        </>
                    );
                },
                width: 200,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="t.c.createdAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'operated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    entity.status !== 'Delisted' &&
                        (entity.status === 'Unrecommended' || entity.status === 'Recommended') && (
                            <Permission permission="Marketplace_Meme_Memecoin_Delist" key="delist">
                                <Spin spinning={!!tableCellViolateLoading[entity.meme_id]} size="small">
                                    <Popconfirm
                                        title={intl.formatMessage({ id: 'c.areyousure' })}
                                        onConfirm={() => {
                                            setTableCellViolateLoading({
                                                [entity.meme_id]: true,
                                            });
                                            delistMemeCoin({
                                                meme_id: entity.meme_id,
                                                creator: entity.creator?.user_id as string,
                                            })
                                                .then(({ data: { code, msg } }) => {
                                                    if (code === 0) {
                                                        message.success(
                                                            intl.formatMessage({ id: 'c.operationCompleted' }),
                                                        );
                                                        setTimeout(() => {
                                                            refreshMemecoin();
                                                            setTableCellViolateLoading({
                                                                [entity.meme_id]: false,
                                                            });
                                                        }, 500);

                                                        return;
                                                    } else {
                                                        message.error(msg || 'Error');
                                                    }
                                                    setTableCellViolateLoading({
                                                        [entity.meme_id]: false,
                                                    });
                                                })
                                                .catch((e) => {
                                                    message.error(get(e, 'response.data.msg', 'Error'));
                                                    setTableCellViolateLoading({
                                                        [entity.meme_id]: false,
                                                    });
                                                });
                                        }}
                                    >
                                        <Typography.Link type="danger">
                                            <FormattedMessage id="mklf.meme.t.c.delist" />
                                        </Typography.Link>
                                    </Popconfirm>
                                </Spin>
                            </Permission>
                        ),
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingMemecoin,
        options: {
            reload: refreshMemecoin,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 3330,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runMemecoin({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="name" style={{ marginRight: 0 }}>
                            <Input allowClear={true} placeholder="Name" style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name="symbol" style={{ marginRight: 0 }}>
                            <Input allowClear={true} placeholder="Ticker" style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={memeCoinStatuOptions}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <SearchUser form={formSearch} />

                        <Form.Item
                            label={formatMessage({ id: 't.c.createdAt' })}
                            name="_created_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingMemecoin}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageNFTMarketplaceMemecoin;
