import { useEffect, useRef, useState } from 'react';
import { useRequest, useSetState } from 'ahooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { App, Button, DatePicker, Flex, Form, Input, Popconfirm, Select, Spin, Typography } from 'antd';
import { ProCard, ProTable } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { get, isEmpty, omitBy, trim } from 'lodash';

/**
 * Components
 */
import TableCellMedia from '@/pages/content-management/shorts/components/table-cell-media';
import Permission from '@/components/permission';
import ComponentSearchFormSelectOperator from '@/components/search-form-select-operator';

/**
 * APIs
 */
import { dShort, shorts } from '@/services/shorts';

/**
 * Types
 */
import type { FormProps, PaginationProps } from 'antd';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { FeShortsParams, ShortsItem } from '@/services/shorts';

/**
 * Constants
 */
const getDefaultTodayDateTime = () => {
    const today = dayjs();
    return [today.startOf('d'), today.endOf('d')];
};

/**
 * State
 */
type State = {
    dataSource: ShortsItem[];
};

const PageContentShorts: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    const intl = useIntl();

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
    });

    const [tableCellLoading, setTableCellLoading] = useSetState<Record<string, boolean>>({});

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useState<FeShortsParams>({
        create_start: getDefaultTodayDateTime()[0].format(),
        create_end: getDefaultTodayDateTime()[1].format(),
    });

    const {
        loading: loadingShorts,
        refresh: refreshShorts,
        run: runShorts,
    } = useRequest(shorts, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                dataSource: data.list,
            });

            setPagination({
                total: data.meta.total,
            });
        },
    });
    /**
     * ChildrenProps
     */

    const formSearchProps: FormProps<FeShortsParams> = {
        form: formSearch,
        layout: 'inline',
        onFinish: (values) => {
            if (values.create_date) {
                values.create_start = values.create_date[0].format();
                values.create_end = values.create_date[1].format();
                values.create_date = undefined;
            }

            if (values.operation_date) {
                values.updated_start = values.operation_date[0].startOf('d').format();
                values.updated_end = values.operation_date[1].endOf('d').format();
                values.operation_date = undefined;
            }

            if (values.fe_is_deleted) {
                values.is_deleted = values.fe_is_deleted === 'normal' ? 'false' : 'true';
            } else {
                values.is_deleted = undefined;
            }

            if (values.description) {
                values.description = trim(values.description);
            }

            values.fe_is_deleted = undefined;

            setPagination({
                current: 1,
            });
            setPayload({
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<ShortsItem, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                width: 50,
                render: (_, __, index) => index + 1,
            },
            {
                dataIndex: 'video_id',
                ellipsis: true,
                title: <FormattedMessage id="cp.t.c.shortsId" />,
                width: 200,
            },
            {
                dataIndex: 'user_id',
                ellipsis: true,
                title: <FormattedMessage id="rm.t.c.postAccUid" />,
                width: 200,
            },
            {
                dataIndex: 'description',
                title: <FormattedMessage id="rm.t.c.content" />,
            },
            {
                dataIndex: '_shorts',
                title: <FormattedMessage id="cp.f.video" />,
                render: (_, entity) => (
                    <TableCellMedia cover={entity.cover} source_url={entity.source_url} large_url={entity.large_url} />
                ),
                width: 120,
            },
            {
                dataIndex: 'deleted_at',
                title: <FormattedMessage id="t.c.status" />,
                render: (_text, entity) =>
                    entity.deleted_at
                        ? intl.formatMessage({ id: 'cp.f.deleted' })
                        : intl.formatMessage({ id: 'rm.b.normal' }),
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="cp.t.c.publishAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 200,
            },
            {
                dataIndex: 'admin_updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: '_menu',
                valueType: 'option',
                width: 200,
                fixed: 'right',
                title: <FormattedMessage id="common.operation" />,
                hideInSearch: true,
                render: (_, entity) => [
                    !entity.deleted_at && (
                        <Permission permission="Content_Shorts_Delete" key="delete">
                            <Spin spinning={!!tableCellLoading[`${entity.id}${entity.video_id}`]} size="small">
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'c.areyousure' })}
                                    onConfirm={() => {
                                        setTableCellLoading({
                                            [`${entity.id}${entity.video_id}`]: true,
                                        });
                                        dShort({
                                            user_id: entity.user_id,
                                            video_id: entity.video_id,
                                        })
                                            .then(({ data: { code, msg } }) => {
                                                if (code === 0) {
                                                    message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                                    refreshShorts();
                                                } else {
                                                    message.error(msg || 'Error');
                                                }
                                                setTableCellLoading({
                                                    [`${entity.id}${entity.video_id}`]: false,
                                                });
                                            })
                                            .catch((e) => {
                                                message.error(get(e, 'response.data.msg', 'Error'));
                                                setTableCellLoading({
                                                    [`${entity.id}${entity.video_id}`]: false,
                                                });
                                            });
                                    }}
                                >
                                    <Typography.Link type="danger">
                                        <FormattedMessage id="b.delete" />
                                    </Typography.Link>
                                </Popconfirm>
                            </Spin>
                        </Permission>
                    ),
                ],
            },
        ],
        dataSource: state.dataSource,
        loading: loadingShorts,
        options: {
            reload: refreshShorts,
        },
        pagination,
        rowKey: (entity) => `${entity.id}${entity.video_id}`,
        scroll: {
            x: 1690,
            y: `calc(100vh - 280px - ${searchRef.current?.clientHeight}px - 8px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runShorts({
            ...payload,
            page: pagination.current as any,
            limit: pagination.pageSize as any,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <Form.Item name="video_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'cp.t.c.shortsId' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="user_id" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.postAccUid' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="description" style={{ marginRight: 0 }}>
                            <Input
                                allowClear={true}
                                placeholder={intl.formatMessage({ id: 'rm.t.c.content' })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="fe_is_deleted" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                showSearch={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: intl.formatMessage({ id: 'rm.b.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: intl.formatMessage({ id: 'cp.f.deleted' }),
                                        value: 'deleted',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 'cp.t.c.publishAt' })}
                            name="create_date"
                            style={{ marginRight: 0 }}
                            initialValue={getDefaultTodayDateTime()}
                        >
                            <DatePicker.RangePicker
                                onChange={(rangeDate) => {
                                    if (!rangeDate || !rangeDate[0] || !rangeDate[1]) {
                                        return;
                                    }
                                    const newStart = dayjs(rangeDate[0].format('YYYY-MM-DD HH:00:00'));
                                    const newDnd = dayjs(rangeDate[1].format('YYYY-MM-DD HH:59:59'));
                                    formSearch.setFieldValue('create_date', [newStart, newDnd]);
                                }}
                                showTime={{ format: 'HH' }}
                                allowClear={true}
                            />
                        </Form.Item>

                        <Form.Item name="operator_account_id" style={{ marginRight: 0 }}>
                            <ComponentSearchFormSelectOperator model="Content_Shorts" />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.operatedAt' })}
                            name="operation_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingShorts}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageContentShorts;
