import { flattenObject } from '@/utils';

/**
 * Resources
 */
// Common
import common from './common';

// Menu
import menu from './menu';

// Permission
import permission from './permission';

// Page
import ambassadors from './pages/ambassadors';
import officialAirdrop from './pages/official-airdrop';
import plazaConfig from './pages/plaza-config';
import userNameManagement from './pages/user-name-management';
// Page users
import usersUsers from './pages/users-users';
import usersSanctionHistory from './pages/users-sanction-history';
import userIdentityVerificationHistory from './pages/user-identity-verification-history';
import systemManagementAccounts from './pages/system-management_accounts';
import systemManagementLogs from './pages/system-management_logs';
import systemManagementExports from './pages/system-management_exports';
import roleManagement from './pages/role-management';

import oldCommon from './modules/common';
import oldMenu from './modules/menu';
// Page content posts
import contentPosts from './pages/content-posts';
// Page digitized-civilization-square
import digitizedCivilizationSquare from './modules/digitized-civilization-square';

// Page super-users
import superUsers from './pages/super-users';
// Page Report-management
import report from './pages/report';
// Page incentive
import incentiveSeasons from './pages/incentive-seasons';

// Page task config
import taskConfig from './pages/task-config';
// Page token config
import tokenConfig from './pages/token_token-config';
// Page token pool
import tokenPool from './pages/token_token-pool';

// Page app version
import appVersion from './pages/app-version';
// Page Creator
import creator from './pages/creator';
import marketplaceNft from './pages/marketplace-nft';
import marketplaceLaunchFun from './pages/marketplace-meme';

export default {
    // Common
    ...flattenObject(common),

    // Menu
    ...flattenObject(menu),

    // Permission
    ...flattenObject(permission),

    // Page
    ...flattenObject(ambassadors),
    ...flattenObject(plazaConfig),
    ...flattenObject(userNameManagement),
    ...flattenObject(roleManagement),
    ...flattenObject(systemManagementLogs),
    ...flattenObject(systemManagementExports),

    // Page
    ...flattenObject(contentPosts),

    // Page - Official AirdropList
    ...flattenObject(officialAirdrop),

    // Page - Users
    ...flattenObject(usersUsers),
    // Page - User Identity Verification History
    ...flattenObject(userIdentityVerificationHistory),
    // Page - Users Sanction History
    ...flattenObject(usersSanctionHistory),
    // Page - super users
    ...flattenObject(superUsers),

    // Page - System-Management Accounts
    ...flattenObject(systemManagementAccounts),
    // Page - Report Management
    ...flattenObject(report),
    // Page - Incentive
    ...flattenObject(incentiveSeasons),

    // Page - task config
    ...flattenObject(taskConfig),

    // Page - token config
    ...flattenObject(tokenConfig),
    // Page - token pool
    ...flattenObject(tokenPool),
    // Page - app version
    ...flattenObject(appVersion),
    // Page - creator
    ...flattenObject(creator),
    // Page - marketplace nft
    ...flattenObject(marketplaceNft),
    // Page - marketplace launch fun
    ...flattenObject(marketplaceLaunchFun),

    /**
     * Old translations
     */
    ...oldCommon,

    // Menu
    ...oldMenu,

    // Page digitized-civilization-square
    ...digitizedCivilizationSquare,
};
