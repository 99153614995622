import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
    createRoutesFromChildren,
    matchRoutes,
    RouterProvider,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import { router } from '@/router';
import { checkToken } from '@/utils';

/**
 * Styles
 */
import 'antd/dist/reset.css';
import './main.less';

/**
 * Constants
 */
checkToken();

Sentry.init({
    dsn: '',
    enabled: false,
    environment: import.meta.env.MODE,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            createRoutesFromChildren,
            matchRoutes,
            useEffect,
            useLocation,
            useNavigationType,
        }),
        Sentry.replayIntegration({
            blockAllMedia: false,
            maskAllInputs: false,
            maskAllText: false,
        }),
    ],
    normalizeDepth: 10,
    release: `admin-fe@${__VERSION__}`,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracePropagationTargets: [
        /^http:\/\/3\.12\.254\.197:3000\/$/,
        /^https:\/\/admin-fe\.actqa\.com\//,
        /^https:\/\/d3dja1dyr84wnb\.cloudfront\.net\//,
        /^https:\/\/d2jgpuabu7gp7a\.cloudfront\.net\//,
    ],
    tracesSampleRate: 1.0,
    beforeSend: (event) => {
        for (const exception of get(event, 'exception.values', [])) {
            for (const frame of get(exception, 'stacktrace.frames', [])) {
                // 忽略来自扩展的错误
                if (frame?.filename?.includes('chrome-extension://') || frame?.filename?.includes('moz-extension://')) {
                    return null;
                }
            }
        }

        return event;
    },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <Sentry.ErrorBoundary fallback={<div>Refresh the page and try again.</div>}>
        <RouterProvider router={router} fallbackElement="Loading..." />
    </Sentry.ErrorBoundary>,
);
