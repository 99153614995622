export default {
    mklf: {
        meme: {
            c: {
                Unrecommended: '未推荐',
                Recommended: '已推荐',
                Launching: '毕业中',
                Launched: '已毕业',
                Delisted: '已下架',
            },
            t: {
                c: {
                    price: '价格（SOC）',
                    marketCap: '市值（SOC）',
                    delist: '下架',
                    group: '社群',
                    website: '网址',
                },
            },
        },
    },
};
