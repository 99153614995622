import { ProCard, ProTable } from '@ant-design/pro-components';
import { useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Drawer, Flex, Form, Input, Select, Typography } from 'antd';
import { get, isEmpty, omitBy } from 'lodash';
import Permission from '@/components/permission';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchUser from '@/components/search-user';

/**
 * APIs
 */
import { ambassadorPublicPool, ambassadorAssign, privatePoolConfigs } from '@/services/ambassadors';

/**
 * Components
 */
import ComponentDetail from '@/pages/ambassadors/ambassadors/components/detail';

/**
 * Types
 */
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import type { DrawerProps, FormProps, PaginationProps } from 'antd';
import type {
    AmbassadorPublicPoolParams,
    PrivatePoolConfigsResult,
    AmbassadorPublicPoolResult,
} from '@/services/ambassadors';

type State = {
    configs: PrivatePoolConfigsResult[];
    dataSource: AmbassadorPublicPoolResult[];
    entity: AmbassadorPublicPoolResult | null;
    open: boolean;
    openDetail: boolean;
    openRemark: boolean;
    selectedRowKeys: React.Key[];
    target: number;
};

const PageAmbassadorsAmbassadorsPublicPool: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [form] = Form.useForm();

    const [formRemark] = Form.useForm();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    const tableRef = useRef<ActionType>();

    /**
     * States
     */
    const [loading, setLoading] = useSetState<Record<string, boolean>>({});

    const [state, setState] = useSetState<State>({
        configs: [],
        dataSource: [],
        entity: null,
        open: false,
        openDetail: false,
        openRemark: false,
        selectedRowKeys: [],
        target: 0,
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setState({
                selectedRowKeys: [],
            });

            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<AmbassadorPublicPoolParams>({
        status: 'unbound',
    });

    /**
     * Requests
     */
    useRequest(privatePoolConfigs, {
        defaultParams: [{ limit: 9999, page: 1 }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                configs: data.list,
            });
        },
    });

    const { loading: loadingBulkUAmbassador, run: runBulkUAmbassador } = useRequest(ambassadorAssign, {
        manual: true,
        onSuccess: ({ data: { code, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            // Close the drawer
            setState({
                open: false,
                openDetail: false,
                openRemark: false,
            });

            // Reload the table
            tableRef.current?.clearSelected?.();
            tableRef.current?.reload?.();
        },
    });

    /**
     * ChildrenProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            open: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button loading={loadingBulkUAmbassador} type="primary" onClick={() => form.submit()}>
                    <FormattedMessage id="b.confirm" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.open,
        title: <FormattedMessage id="b.assign" />,
        afterOpenChange: (open) => open || form.resetFields(),
        onClose: () => {
            setState({
                open: false,
            });
        },
    };

    const drawerDetailProps: DrawerProps = {
        maskClosable: false,
        open: state.openDetail,
        size: 'large',
        title: <FormattedMessage id="b.details" />,
        onClose: () => {
            setState({
                openDetail: false,
            });
        },
    };

    const drawerRemarkProps: DrawerProps = {
        footer: (
            <Flex gap={8} justify="flex-end">
                <Button
                    onClick={() => {
                        setState({
                            openRemark: false,
                        });
                    }}
                >
                    <FormattedMessage id="b.cancel" />
                </Button>

                <Button loading={loadingBulkUAmbassador} type="primary" onClick={() => formRemark.submit()}>
                    <FormattedMessage id="b.confirm" />
                </Button>
            </Flex>
        ),
        maskClosable: false,
        open: state.openRemark,
        title: <FormattedMessage id="b.remark" />,
        afterOpenChange: (open) => open || formRemark.resetFields(),
        onClose: () => {
            setState({
                openRemark: false,
            });
        },
    };

    const formProps: FormProps = {
        form,
        layout: 'vertical',
        onFinish: (values) => {
            runBulkUAmbassador(values);
        },
    };

    const formRemarkProps: FormProps = {
        form: formRemark,
        layout: 'vertical',
        onFinish: (values) => {
            runBulkUAmbassador(values);
        },
    };

    const formSearchProps: FormProps = {
        form: formSearch,
        layout: 'inline',
        initialValues: {
            status: 'unbound',
        },
        onFinish: (values) => {
            if (values.apply_date) {
                values.apply_end = values.apply_date[1].endOf('d').format();
                values.apply_start = values.apply_date[0].startOf('d').format();
                values.apply_date = undefined;
            }

            setState({
                selectedRowKeys: [],
            });

            setPagination({
                current: 1,
                pageSize: 20,
            });

            setPayload({
                account_id: undefined,
                apply_end: undefined,
                apply_start: undefined,
                operation_end: undefined,
                operation_start: undefined,
                operator_account_id: undefined,
                order_by: undefined,
                order_sort: undefined,
                parent_user_id: undefined,
                request_id: undefined,
                status: undefined,
                user_id: undefined,
                nickname: undefined,
                request_email: undefined,
                username: undefined,

                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<AmbassadorPublicPoolResult, any> = {
        actionRef: tableRef,
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.user" />,
                width: 200,
                render: (_, entity) => {
                    const { name = '-', nickname = '-' } = entity.user_info || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{name}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: '1',
                ellipsis: true,
                title: <FormattedMessage id="t.c.fullName" />,
                width: 200,
                render: (_, entity) => {
                    const { first_name = '', last_name = '' } = get(entity, 'application.request.ambassador_info', {});

                    return `${first_name} ${last_name}`;
                },
            },
            {
                dataIndex: ['application', 'request', 'email'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.email" />,
                width: 200,
            },
            {
                dataIndex: ['application', 'request', 'social_media_presence', 'link'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.link" />,
                width: 200,
                renderText: (text) => (
                    <a href={text?.startsWith('http') ? text : `http://${text}`} target="_blank">
                        {text}
                    </a>
                ),
            },
            {
                dataIndex: ['application', 'request', 'social_media_presence', 'fan_count'],
                title: <FormattedMessage id="p.ambassadors.t.c.followers" />,
                width: 120,
            },
            {
                dataIndex: 'created_at',
                title: <FormattedMessage id="t.c.appliedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="p.ambassadors.t.c.status" />,
                width: 120,
                valueEnum: {
                    normal: <FormattedMessage id="p.ambassadors.t.c.assigned" />,
                    unbound: <FormattedMessage id="p.ambassadors.t.c.unassigned" />,
                },
            },
            {
                dataIndex: ['account', 'name'],
                ellipsis: true,
                title: <FormattedMessage id="t.c.owner" />,
                width: 120,
            },
            {
                dataIndex: ['operator_account', 'name'],
                title: <FormattedMessage id="t.c.operatedBy" />,
                width: 120,
            },
            {
                dataIndex: 'updated_at',
                title: <FormattedMessage id="t.c.operatedAt" />,
                valueType: 'dateTime',
                width: 200,
            },
            {
                fixed: 'right',
                key: 'option',
                title: <FormattedMessage id="t.c.action" />,
                valueType: 'option',
                width: 200,
                render: (_, entity) => [
                    <Permission permission="Ambassadors_Ambassadors_Public-Pool_Assign">
                        <a
                            key="assign"
                            onClick={() => {
                                form.setFieldsValue({
                                    ids: [entity.id],
                                });

                                setState({
                                    open: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.assign" />
                        </a>
                    </Permission>,
                    <a
                        key="details"
                        onClick={() => {
                            setState({
                                entity,
                                openDetail: true,
                            });
                        }}
                    >
                        <FormattedMessage id="b.details" />
                    </a>,
                ],
            },
        ],
        pagination,
        params: payload,
        rowKey: 'id',
        scroll: {
            x: 1740,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
        tableAlertOptionRender: false,
        tableAlertRender: false,
        toolbar: {
            actions: [
                state.selectedRowKeys.length ? (
                    <Flex gap={8} key="bulk">
                        <Button
                            type="primary"
                            onClick={() => {
                                form.setFieldsValue({
                                    ids: state.selectedRowKeys,
                                });

                                setState({
                                    entity: null,
                                    open: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.transfer" />
                        </Button>

                        <Button
                            onClick={() => {
                                formRemark.setFieldsValue({
                                    ids: state.selectedRowKeys,
                                });

                                setState({
                                    entity: null,
                                    openRemark: true,
                                });
                            }}
                        >
                            <FormattedMessage id="b.remark" />
                        </Button>
                    </Flex>
                ) : null,
            ],
        },
        onLoadingChange: (loading) => {
            setLoading({
                table: loading as boolean,
            });
        },
        request: async (params) => {
            const res = await ambassadorPublicPool({
                ...params,
                limit: params.pageSize,
                page: params.current,
            });

            if (get(res, 'data.code') !== 0) {
                message.error(get(res, 'data.msg'));
            }

            return {
                data: get(res, 'data.data.list', []),
                success: get(res, 'data.code') === 0,
                total: get(res, 'data.data.meta.total', 0),
            };
        },
    };

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap="wrap">
                        <SearchUser form={formSearch} />

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={[
                                    {
                                        label: formatMessage({ id: 'p.ambassadors.t.c.assigned' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.ambassadors.t.c.unassigned' }),
                                        value: 'unbound',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="p.ambassadors.t.c.status" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Permission permission="Ambassadors_Ambassadors_Ambassadors_All-Data">
                            <Form.Item name="account_id" style={{ marginRight: 0 }}>
                                <Select
                                    allowClear={true}
                                    optionFilterProp="label"
                                    options={state.configs.map((config) => ({
                                        label: config.account.name,
                                        value: config.account.id,
                                    }))}
                                    placeholder={<FormattedMessage id="t.c.owner" />}
                                    showSearch={true}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>
                        </Permission>

                        <Form.Item
                            label={formatMessage({ id: 't.c.createdAt' })}
                            name="apply_date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loading['table'] === true}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />

            <Drawer {...drawerProps}>
                <Form {...formProps}>
                    <Form.Item hidden name="ids">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={formatMessage({ id: 't.c.account' })}
                        name="account_id"
                        rules={[{ required: true }]}
                    >
                        <Select
                            optionFilterProp="label"
                            options={state.configs.map((config) => ({
                                label: config.account.name,
                                value: config.account.id,
                            }))}
                            showSearch={true}
                        />
                    </Form.Item>
                </Form>
            </Drawer>

            <Drawer {...drawerDetailProps}>
                <ComponentDetail dataSource={state.entity as AmbassadorPublicPoolResult} />
            </Drawer>

            <Drawer {...drawerRemarkProps}>
                <Form {...formRemarkProps}>
                    <Form.Item hidden name="ids">
                        <Input />
                    </Form.Item>

                    <Form.Item label={formatMessage({ id: 'b.remark' })} name="remark">
                        <Input.TextArea autoSize={{ minRows: 3 }} maxLength={1000} showCount={true} />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default PageAmbassadorsAmbassadorsPublicPool;
