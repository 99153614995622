import { ProCard, ProTable } from '@ant-design/pro-components';
import { useCountDown, useRequest, useSetState } from 'ahooks';
import { App, Button, DatePicker, Flex, Form, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { get, isEmpty, omitBy } from 'lodash';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchUser from '@/components/search-user';

/**
 * APIs
 */
import { ambassadorDatasExport, ambassadorDatas, privatePoolConfigs } from '@/services/ambassadors';

/**
 * Components
 */
import Permission from '@/components/permission';

/**
 * Types
 */
import type { ProTableProps } from '@ant-design/pro-components';
import type { FormProps, PaginationProps } from 'antd';
import type { AmbassadorDatasParams, AmbassadorDatasResult, PrivatePoolConfigsResult } from '@/services/ambassadors';

type State = {
    configs: PrivatePoolConfigsResult[];
    dataSource: AmbassadorDatasResult[];
    target: number;
};

const PageAmbassadorsDataAmbassadorData: React.FC = () => {
    /**
     * Hooks
     */
    const { message } = App.useApp();

    const [formSearch] = Form.useForm();

    const { formatMessage } = useIntl();

    const searchRef = useRef<HTMLDivElement>(null);

    /**
     * States
     */
    const [state, setState] = useSetState<State>({
        dataSource: [],
        target: 0,
        configs: [],
    });

    const [countdown] = useCountDown({
        targetDate: state.target,
    });

    /**
     * Paginations
     */
    const [pagination, setPagination] = useSetState<PaginationProps>({
        current: 1,
        pageSize: 20,
        onChange: (page, pageSize) => {
            setPagination({
                current: page,
                pageSize,
            });
        },
    });

    /**
     * Payloads
     */
    const [payload, setPayload] = useSetState<AmbassadorDatasParams>({
        status: 'normal',
        date_start: dayjs().startOf('d').format(),
        date_end: dayjs().endOf('d').format(),
    });

    /**
     * Requests
     */
    useRequest(privatePoolConfigs, {
        defaultParams: [{ limit: 9999, page: 1 }],
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setState({
                configs: data.list,
            });
        },
    });

    const {
        loading: loadingAmbassadorDatas,
        refresh: refreshAmbassadorDatas,
        run: runAmbassadorDatas,
    } = useRequest(ambassadorDatas, {
        manual: true,
        onSuccess: ({ data: { code, data, msg } }) => {
            if (code !== 0) {
                return message.error(msg);
            }

            setPagination({
                current: data.meta.currentPage,
                pageSize: data.meta.perPage,
                total: data.meta.total,
            });

            setState({
                dataSource: data.list,
            });
        },
    });

    /**
     * ChildrenProps
     */
    const formSearchProps: FormProps = {
        form: formSearch,
        initialValues: {
            date: [dayjs().startOf('d'), dayjs().endOf('d')],
            status: 'normal',
        },
        layout: 'inline',
        onFinish: (values) => {
            if (values.date) {
                values.date_end = values.date[1].endOf('d').format();
                values.date_start = values.date[0].startOf('d').format();
                values.date = undefined;
            }

            setPagination({
                current: 1,
                pageSize: 20,
            });

            setPayload({
                account_id: undefined,
                date_end: undefined,
                date_start: undefined,
                user_id: undefined,
                status: undefined,
                nickname: undefined,
                username: undefined,
                ...omitBy(values, isEmpty),
            });
        },
    };

    const proTableProps: ProTableProps<AmbassadorDatasResult, any> = {
        bordered: true,
        columns: [
            {
                dataIndex: 'index',
                fixed: 'left',
                title: 'No.',
                valueType: 'index',
                width: 50,
            },
            {
                dataIndex: 'user_id',
                title: <FormattedMessage id="t.c.user" />,
                width: 200,
                render: (_, entity) => {
                    const { name = '-', nickname = '-' } = entity.user_info || {};

                    return (
                        <>
                            <div>{nickname}</div>

                            <Typography.Text copyable={{ text: entity.user_id, tooltips: 'Copy UID' }}>
                                @{name}
                            </Typography.Text>
                        </>
                    );
                },
            },
            {
                dataIndex: '1',
                ellipsis: true,
                title: <FormattedMessage id="t.c.fullName" />,
                width: 200,
                render: (_, entity) => {
                    const { first_name = '', last_name = '' } = get(entity, 'application.request.ambassador_info', {});

                    return `${first_name} ${last_name}`;
                },
            },
            {
                dataIndex: ['account', 'name'],
                title: <FormattedMessage id="t.c.owner" />,
            },
            {
                dataIndex: 'status',
                title: <FormattedMessage id="t.c.status" />,
                width: 200,
                valueType: 'select',
                valueEnum: {
                    normal: formatMessage({ id: 'p.ambassadors.publicPool.status.normal' }),
                    unbound: formatMessage({ id: 'p.ambassadors.publicPool.status.unbound' }),
                    deregistered: formatMessage({ id: 'p.ambassadors.publicPool.status.revoked' }),
                },
            },
            {
                dataIndex: 'post_count',
                title: <FormattedMessage id="p.ambassadors.t.c.postTotal" />,
                tooltip: <FormattedMessage id="p.ambassadors.tips.ambassadorData.posts" />,
                width: 200,
                valueType: 'digit',
            },
            {
                dataIndex: 'register_count',
                title: <FormattedMessage id="p.ambassadors.t.c.registerTotal" />,
                tooltip: <FormattedMessage id="p.ambassadors.tips.ambassadorData.invitedUsers" />,
                width: 200,
                valueType: 'digit',
            },
            {
                dataIndex: 'post_user_count',
                title: <FormattedMessage id="p.ambassadors.t.c.postUserTotal" />,
                tooltip: <FormattedMessage id="p.ambassadors.tips.ambassadorData.usersPosted" />,
                width: 200,
                valueType: 'digit',
            },
            {
                dataIndex: 'active_user_count',
                title: <FormattedMessage id="p.ambassadors.t.c.activeUserTotal" />,
                width: 200,
                valueType: 'digit',
            },
            {
                dataIndex: 'realname_count',
                title: <FormattedMessage id="p.ambassadors.t.c.realnameTotal" />,
                tooltip: <FormattedMessage id="p.ambassadors.tips.ambassadorData.usersVerified" />,
                width: 200,
                valueType: 'digit',
            },
        ],
        dataSource: state.dataSource,
        loading: loadingAmbassadorDatas,
        options: {
            reload: refreshAmbassadorDatas,
        },
        pagination,
        rowKey: 'id',
        scroll: {
            x: 1850,
            y: `calc(100vh - 224px - ${searchRef.current?.clientHeight || 64}px - 16px - 48px)`,
        },
        search: false,
    };

    /**
     * Effects
     */
    useEffect(() => {
        runAmbassadorDatas({
            ...payload,
            limit: pagination.pageSize,
            page: pagination.current,
        });
    }, [pagination.current, pagination.pageSize, payload]);

    return (
        <>
            <ProCard className="mb-16" ref={searchRef}>
                <Form {...formSearchProps}>
                    <Flex gap={16} wrap={true}>
                        <SearchUser form={formSearch} />

                        <Form.Item name="status" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                options={[
                                    {
                                        label: formatMessage({ id: 'p.ambassadors.publicPool.status.normal' }),
                                        value: 'normal',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.ambassadors.publicPool.status.unbound' }),
                                        value: 'unbound',
                                    },
                                    {
                                        label: formatMessage({ id: 'p.ambassadors.publicPool.status.revoked' }),
                                        value: 'deregistered',
                                    },
                                ]}
                                placeholder={<FormattedMessage id="t.c.status" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item name="account_id" style={{ marginRight: 0 }}>
                            <Select
                                allowClear={true}
                                optionFilterProp="label"
                                options={state.configs.map((config) => ({
                                    label: config.account.name,
                                    value: config.account.id,
                                }))}
                                placeholder={<FormattedMessage id="t.c.owner" />}
                                showSearch={true}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({ id: 't.c.dateRange' })}
                            name="date"
                            style={{ marginRight: 0 }}
                        >
                            <DatePicker.RangePicker allowClear={true} />
                        </Form.Item>

                        <Button
                            loading={loadingAmbassadorDatas}
                            type="primary"
                            onClick={() => {
                                formSearch.submit();
                            }}
                        >
                            <FormattedMessage id="b.search" />
                        </Button>

                        <Button
                            onClick={() => {
                                formSearch.resetFields();
                            }}
                        >
                            <FormattedMessage id="b.reset" />
                        </Button>

                        <Permission permission="Ambassadors_Data_Ambassador-Data_Export">
                            <Button
                                disabled={countdown !== 0}
                                onClick={() => {
                                    formSearch.validateFields().then(async (values) => {
                                        if (values.date) {
                                            values.date_end = values.date[1].endOf('d').format();
                                            values.date_start = values.date[0].startOf('d').format();
                                        }

                                        try {
                                            const { data } = await ambassadorDatasExport(omitBy(values, isEmpty));

                                            if (data.code !== 0) {
                                                return message.error(data.msg);
                                            }
                                            message.success(formatMessage({ id: 'm.exportTips' }));

                                            setState({
                                                target: Date.now() + 1000 * 10,
                                            });
                                        } catch {
                                            message.error('Error');
                                        }
                                    });
                                }}
                            >
                                <FormattedMessage id="b.export" />
                                {countdown === 0 ? null : `(${Math.round(countdown / 1000)}s)`}
                            </Button>
                        </Permission>
                    </Flex>
                </Form>
            </ProCard>

            <ProTable {...proTableProps} />
        </>
    );
};

export default PageAmbassadorsDataAmbassadorData;
