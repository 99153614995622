import { useBoolean, useSetState } from 'ahooks';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Drawer, Form, Input, Row, Space, App, Avatar, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';

/**
 * Utils
 */
import { apiErrorMsg } from '@/utils/apiErrorMsg';

/**
 * APIs
 */
import { UserPrefileResetItem, userProfileReset } from '@/services/user';

/**
 * Constants
 */
const WEB2_ASSETS_URL = import.meta.env.VITE_WEB2_ASSETS || '';
const HasResetStyle: React.CSSProperties = {
    display: 'none',
    color: '#999',
    cursor: 'not-allowed',
};
const RowGutter = 12;
const FormItemSpan = 20;
const resetBtnSpan = 4;
const resetBtnStyle: React.CSSProperties = {
    paddingBottom: 20,
};

/**
 * Types
 */
import type { DrawerProps, FormProps } from 'antd';
import type { IntlShape } from 'react-intl';
import type { PostBenefitsBody } from '@/services/super-users';
import type { User } from '@/services/user';

type Props = {
    open: boolean;
    user: User | null;
    closeModelForm: (reload?: boolean) => void;
};

// type State = {
//     uploadPoster: boolean;
// };
type hasResetType = {
    avatar: boolean;
    name: boolean;
    nickname: boolean;
    bio: boolean;
};

const ComponentManageProfileDrawer: React.FC<Props> = ({ open, user, closeModelForm }) => {
    /**
     * Hooks
     */
    const { message } = App.useApp();
    const intl: IntlShape = useIntl();

    /**
     * State
     */
    const [submitting, { setFalse: setSubmittingFlase, setTrue: setSubmittingTrue }] = useBoolean(false);
    const [form] = Form.useForm();
    // const [state, setState] = useSetState<State>({
    //     uploadPoster: false,
    // });
    const [hasReset, setHasReset] = useSetState<hasResetType>({
        avatar: false,
        name: false,
        nickname: false,
        bio: false,
    });

    /**
     * DrawerProps
     */
    const drawerProps: DrawerProps = {
        footer: (
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <Button type="default" onClick={() => closeModelForm()}>
                        {intl.formatMessage({ id: 'b.cancel' })}
                    </Button>
                    <Button
                        type="primary"
                        loading={submitting}
                        onClick={() => {
                            form.validateFields()
                                .then(async (formData) => {
                                    setSubmittingTrue();
                                    try {
                                        const resetItem: UserPrefileResetItem[] = [];
                                        Object.entries(hasReset).forEach(([key, value]) => {
                                            if (value) {
                                                resetItem.push(key as UserPrefileResetItem);
                                            }
                                        });
                                        const res = await userProfileReset(user?.user_id as string, {
                                            remark: formData.remark,
                                            items: resetItem,
                                        });
                                        if (get(res, 'data.code') === 0) {
                                            message.success(intl.formatMessage({ id: 'c.operationCompleted' }));
                                            closeModelForm(true);
                                        } else {
                                            apiErrorMsg(message, intl, {}, res.data);
                                            setSubmittingFlase();
                                        }
                                    } catch (e: any) {
                                        apiErrorMsg(message, intl, {}, get(e, 'response.data'));
                                        setSubmittingFlase();
                                    }
                                })
                                .catch(() => {});
                        }}
                    >
                        {intl.formatMessage({ id: 'b.confirm' })}
                    </Button>
                </Space>
            </div>
        ),
        destroyOnClose: true,
        maskClosable: false,
        open: open,
        title: <FormattedMessage id="p.uu.btn.mangProfile" />,
        afterOpenChange: (open) => {
            if (!open) {
                setSubmittingFlase();
                form.resetFields();
                setHasReset({
                    avatar: false,
                    name: false,
                    nickname: false,
                    bio: false,
                });
            } else if (user) {
                const reviewImg = [];
                if (user.avatar) {
                    reviewImg.push({
                        name: 'image',
                        url: user.avatar,
                    });
                }
                // user.avatar = 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png';
                form.setFieldsValue({
                    _avator: 'reviewImg',
                    name: user.name,
                    nickname: user.nickname,
                    bio: user.bio,
                });
                // setState({
                //     uploadPoster: true,
                // });
            }
        },
        onClose: () => {
            closeModelForm();
        },
    };

    /**
     * ProformProps
     */
    const formProps: FormProps<PostBenefitsBody> = {
        form: form,
        layout: 'vertical',
        validateTrigger: 'onBlur',
    };

    /**
     * UploadProps
     */
    // const uploadProps: UploadProps = {
    //     accept: 'image/jpg,image/jpeg,image/png',
    //     listType: 'picture-card',
    //     maxCount: 1,
    //     customRequest: (options) => {
    //         uploadImage({ file: options.file as File }).then(options.onSuccess, options.onError);
    //     },
    // };

    return (
        <Drawer {...drawerProps}>
            <Form {...formProps}>
                <Row align="bottom" gutter={RowGutter}>
                    <Col span={6}>
                        <Form.Item
                            label="Avator"
                            name="_avator"
                            // valuePropName="src"
                            // getValueFromEvent={(e) => {
                            //     console.log(e);
                            //     setState({
                            //         uploadPoster: e.file.status !== 'done' || e.file.response?.data?.code !== 0,
                            //     });

                            //     if (e.file.status === 'done') {
                            //         e.file.url = e.file.response.data.data?.url;

                            //         if (e.file.response.data.code !== 0) {
                            //             message.error(e.file.response.data.msg);
                            //             return [];
                            //         }
                            //     }

                            //     return e.fileList;
                            // }}
                            rules={[{ required: false }]}
                        >
                            <div style={hasReset.avatar ? { filter: 'grayscale(100%)' } : undefined}>
                                {user?.avatar && !hasReset.avatar ? (
                                    <Image
                                        width={64}
                                        height={64}
                                        // style={{ borderRadius: '50%' }}
                                        src={
                                            user.avatar.includes('https')
                                                ? user.avatar
                                                : `${WEB2_ASSETS_URL}/${user.avatar}`
                                        }
                                    />
                                ) : (
                                    <Avatar shape="square" size={64} icon={<UserOutlined />} />
                                )}
                            </div>
                            {/* <Upload {...uploadProps} disabled={true}>
                                {state.uploadPoster && (
                                    <div>
                                        <PlusOutlined />

                                        <div style={{ marginTop: 8 }}>上传</div>
                                    </div>
                                )}
                            </Upload> */}
                        </Form.Item>
                    </Col>
                    {user?.avatar && (
                        <Col span={resetBtnSpan} style={resetBtnStyle}>
                            <a
                                onClick={() => {
                                    !hasReset.avatar &&
                                        setHasReset({
                                            avatar: true,
                                        });
                                }}
                                style={hasReset.avatar ? HasResetStyle : undefined}
                            >
                                Reset
                            </a>
                        </Col>
                    )}
                </Row>

                <Row align="bottom" gutter={RowGutter}>
                    <Col span={FormItemSpan}>
                        <Form.Item name="name" label="Username" rules={[{ required: false }]}>
                            <Input disabled={true} showCount allowClear maxLength={50} />
                        </Form.Item>
                    </Col>
                    {user?.name && (
                        <Col span={resetBtnSpan} style={resetBtnStyle}>
                            <a
                                onClick={() => {
                                    !hasReset.name &&
                                        setHasReset({
                                            name: true,
                                        });
                                }}
                                style={hasReset.name ? HasResetStyle : undefined}
                            >
                                Reset
                            </a>
                        </Col>
                    )}
                </Row>

                <Row align="bottom" gutter={RowGutter}>
                    <Col span={FormItemSpan}>
                        <Form.Item name="nickname" label="Nickname" rules={[{ required: false }]}>
                            <Input disabled={true} showCount allowClear maxLength={50} />
                        </Form.Item>
                    </Col>
                    {user?.nickname && (
                        <Col span={resetBtnSpan} style={resetBtnStyle}>
                            <a
                                onClick={() => {
                                    !hasReset.nickname &&
                                        setHasReset({
                                            nickname: true,
                                        });
                                }}
                                style={hasReset.nickname ? HasResetStyle : undefined}
                            >
                                Reset
                            </a>
                        </Col>
                    )}
                </Row>

                <Row align="bottom" gutter={RowGutter}>
                    <Col span={FormItemSpan}>
                        <Form.Item name="bio" label="Bio" rules={[{ required: false }]}>
                            <Input.TextArea disabled={true} showCount allowClear maxLength={120} rows={4} />
                        </Form.Item>
                    </Col>
                    {user?.bio && (
                        <Col span={resetBtnSpan} style={resetBtnStyle}>
                            <a
                                onClick={() => {
                                    !hasReset.bio &&
                                        setHasReset({
                                            bio: true,
                                        });
                                }}
                                style={hasReset.bio ? HasResetStyle : undefined}
                            >
                                Reset
                            </a>
                        </Col>
                    )}
                </Row>

                <Row align="bottom" gutter={RowGutter}>
                    <Col span={FormItemSpan}>
                        <Form.Item
                            name="remark"
                            // label={intl.formatMessage({ id: 'p.uu.columns.remark' })}
                            label="Remark"
                            rules={[{ required: true }]}
                        >
                            <Input.TextArea showCount allowClear maxLength={1000} rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
};

export default ComponentManageProfileDrawer;
